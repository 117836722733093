<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="详情" name="first">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_01.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_02.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_03.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_04.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_05.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_06.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_07.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_08.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_09.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_10.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_11.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_12.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_13.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_14.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_15.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_16.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_17.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_18.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_19.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/1.WIFI摄像机/室外机详情页（200万像素）/Z81/images/Z81_20.jpg" lazy width="100%" height="100%">
      </el-tab-pane>
      <el-tab-pane label="规格" name="second">
        <table width="60%" border="1" cellpadding="10" cellspacing="0" bordercolor="#000000" style="border-collapse:collapse;">
          <tr>
            <td>类别</td>
            <td>类型</td>
            <td>参数</td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="视频" name="third">
        <video src="../../../../../public/images/三级页面/视频样例/此视频借用到产品CS48Q视频.mp4"  width="100%" controls="controls"></video>
      </el-tab-pane>
      <el-tab-pane label="技术支持" name="fourth">

      </el-tab-pane>
      <el-tab-pane label="扩展" name="kz">

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Z81",
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
    }
  }
}
</script>

<style scoped>
.tabs{
  margin-top: 100px;
}
/deep/.el-tabs__item{
  font-size: 16px;
}
</style>